import React from "react";
import OurStoryMain from "../components/OurStoryUiComponents/OurStoryMain";
import Layout from "../components/Layout/Layout";
import seoteam from "../images/seoteam.webp";
import { SEO } from "../components/seo";
import { graphql } from "gatsby";
//@ts-ignore
const OurStory = ({data}) => {
  return (
    <Layout location="/our-story">
      <SEO
        title="Our story"
        description="Learn more about our exciting story - how we guide you all the way, realizing the potential to release the full potential of the Cloud!"
        type="website"
        image={seoteam}
      />
      <OurStoryMain />
    </Layout>
  );
};

export default OurStory;

