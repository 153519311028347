// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "OurStoryMain-module--aboutMainCircleBlurBlue--7a0b4";
export var authorLinks = "OurStoryMain-module--authorLinks--49d12";
export var authorName = "OurStoryMain-module--authorName--19665";
export var bHeading = "OurStoryMain-module--bHeading--4eeb6";
export var bannerMargin = "OurStoryMain-module--bannerMargin--042ac";
export var blogImageContainer = "OurStoryMain-module--blogImageContainer--349c5";
export var buttonMainClass = "OurStoryMain-module--buttonMainClass--5ed33";
export var caseChallengeImage = "OurStoryMain-module--caseChallengeImage--a434e";
export var caseTestimonialImage = "OurStoryMain-module--caseTestimonialImage--46b36";
export var cloudReachImage = "OurStoryMain-module--cloudReachImage--cf8d1";
export var cloudReachSection = "OurStoryMain-module--cloudReachSection--5f275";
export var cloudReachText = "OurStoryMain-module--cloudReachText--f7a11";
export var contactButton = "OurStoryMain-module--contactButton--ace69";
export var coreValue = "OurStoryMain-module--coreValue--c167f";
export var coreValueColumbLeft = "OurStoryMain-module--coreValueColumbLeft--cd1cd";
export var coreValueColumbRight = "OurStoryMain-module--coreValueColumbRight--076f3";
export var coreValueItem = "OurStoryMain-module--coreValueItem--1b014";
export var coreValueItemBox = "OurStoryMain-module--coreValueItemBox--1037f";
export var coreValuesHeading = "OurStoryMain-module--coreValuesHeading--e9ebc";
export var description = "OurStoryMain-module--description--55e2a";
export var development = "OurStoryMain-module--development--3834d";
export var gdnPromotePic = "OurStoryMain-module--gdnPromotePic--2fe78";
export var greenColor = "OurStoryMain-module--greenColor--f6b9a";
export var head = "OurStoryMain-module--head--6b07c";
export var heroBackgroundCircleLeft = "OurStoryMain-module--heroBackgroundCircleLeft--908f4";
export var heroBackgroundCircleLeftStory = "OurStoryMain-module--heroBackgroundCircleLeftStory--0c837";
export var heroBackgroundCircleLeftStorySecond = "OurStoryMain-module--heroBackgroundCircleLeftStorySecond--4642b";
export var heroBackgroundInnerCircleLeft = "OurStoryMain-module--heroBackgroundInnerCircleLeft--653a8";
export var heroBackgroundInnerCircleLeftSecond = "OurStoryMain-module--heroBackgroundInnerCircleLeftSecond--d7b5b";
export var heroWhiteBlurCirce = "OurStoryMain-module--heroWhiteBlurCirce--65a4a";
export var inNumbersImage = "OurStoryMain-module--inNumbersImage--d8570";
export var inNumbersItem = "OurStoryMain-module--inNumbersItem--90c83";
export var keyTopicsImage = "OurStoryMain-module--keyTopicsImage--e47a9";
export var knowUs = "OurStoryMain-module--knowUs--9ab5a";
export var knowUsHeading = "OurStoryMain-module--knowUsHeading--80300";
export var knowUsImage = "OurStoryMain-module--knowUsImage--304ed";
export var knowUsImageDiv = "OurStoryMain-module--knowUsImageDiv--2b9ae";
export var knowUsOurStory = "OurStoryMain-module--knowUsOurStory--d2a43";
export var knowUsOurStoryText = "OurStoryMain-module--knowUsOurStoryText--b2da1";
export var knowUsText = "OurStoryMain-module--knowUsText--fc8a8";
export var mobSliderDiv = "OurStoryMain-module--mobSliderDiv--2dcc3";
export var ourStory = "OurStoryMain-module--ourStory--1a37a";
export var ourStoryDescPara = "OurStoryMain-module--ourStoryDescPara--029e7";
export var ourStoryImg = "OurStoryMain-module--ourStoryImg--7bfd3";
export var ourStoryImgDiv = "OurStoryMain-module--ourStoryImgDiv--10fe7";
export var ourStoryText = "OurStoryMain-module--ourStoryText--5e7b9";
export var ourStoryTwoColumn = "OurStoryMain-module--ourStoryTwoColumn--99d04";
export var ourTeamHeader = "OurStoryMain-module--ourTeamHeader--1538a";
export var ourTeamSection = "OurStoryMain-module--ourTeamSection--81a45";
export var singleBenefitTextBox = "OurStoryMain-module--singleBenefitTextBox--6feca";
export var singleCaseChallenge = "OurStoryMain-module--singleCaseChallenge--8d364";
export var singleCaseContentBlock = "OurStoryMain-module--singleCaseContentBlock--09bd2";
export var singleCaseImageBox1 = "OurStoryMain-module--singleCaseImageBox1--2177c";
export var singleCaseSolution = "OurStoryMain-module--singleCaseSolution--64f2e";
export var singleCaseTestimonial = "OurStoryMain-module--singleCaseTestimonial--a81d9";
export var singleCaseTextBox = "OurStoryMain-module--singleCaseTextBox--2ee4d";
export var sliderButtonContainer = "OurStoryMain-module--sliderButtonContainer--f1ab8";
export var sliderDescription = "OurStoryMain-module--sliderDescription--a879c";
export var sliderItem = "OurStoryMain-module--sliderItem--a6cc8";
export var sliderWrapper = "OurStoryMain-module--sliderWrapper--12347";
export var storyPromotePic = "OurStoryMain-module--storyPromotePic--2424c";
export var storyPromotePicSecond = "OurStoryMain-module--storyPromotePicSecond--86cd7";
export var teamSliderItem = "OurStoryMain-module--teamSliderItem--ffc4d";
export var testimonialContent = "OurStoryMain-module--testimonialContent--b6ae9";
export var topHeading = "OurStoryMain-module--topHeading--c8a68";
export var yourBenefits = "OurStoryMain-module--yourBenefits--cbc6c";