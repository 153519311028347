// extracted by mini-css-extract-plugin
export var ArrowContainer = "SharedSlider-module--ArrowContainer--93fa8";
export var authorGithub = "SharedSlider-module--authorGithub--9804d";
export var authorLinkedIn = "SharedSlider-module--authorLinkedIn--fcb04";
export var authorName = "SharedSlider-module--authorName--10947";
export var blogImageContainer = "SharedSlider-module--blogImageContainer--fb38a";
export var caseChallengeImage = "SharedSlider-module--caseChallengeImage--c3de3";
export var caseStudiesButtonsContainer = "SharedSlider-module--caseStudiesButtonsContainer--dcd98";
export var caseStudiesDescription = "SharedSlider-module--caseStudiesDescription--dac42";
export var caseTestimonialImage = "SharedSlider-module--caseTestimonialImage--8c3bd";
export var cloudInsight = "SharedSlider-module--cloudInsight--26b39";
export var cloudInsightHeader = "SharedSlider-module--cloudInsightHeader--fa44f";
export var contactButton = "SharedSlider-module--contactButton--2f37e";
export var coreValue = "SharedSlider-module--coreValue--b710c";
export var coreValueColumbLeft = "SharedSlider-module--coreValueColumbLeft--0dd1b";
export var coreValueColumbRight = "SharedSlider-module--coreValueColumbRight--3cacb";
export var coreValueItem = "SharedSlider-module--coreValueItem--bf7b6";
export var coreValueItemBox = "SharedSlider-module--coreValueItemBox--8b62e";
export var designation = "SharedSlider-module--designation--ef1eb";
export var gdnPromotePic = "SharedSlider-module--gdnPromotePic--91f0b";
export var greenColor = "SharedSlider-module--greenColor--49bc9";
export var heroBackgroundCircleLeft = "SharedSlider-module--heroBackgroundCircleLeft--67824";
export var heroBackgroundInnerCircleLeft = "SharedSlider-module--heroBackgroundInnerCircleLeft--699fc";
export var heroBackgroundInnerCircleOverflowText = "SharedSlider-module--heroBackgroundInnerCircleOverflowText--cc748";
export var heroWhiteBlurCirce = "SharedSlider-module--heroWhiteBlurCirce--a887c";
export var inNumbersImage = "SharedSlider-module--inNumbersImage--e93de";
export var inNumbersItem = "SharedSlider-module--inNumbersItem--7c6f2";
export var keyTopicsImage = "SharedSlider-module--keyTopicsImage--8d71a";
export var knowUs = "SharedSlider-module--knowUs--66e2c";
export var knowUsImage = "SharedSlider-module--knowUsImage--1d05e";
export var knowUsText = "SharedSlider-module--knowUsText--9c4d1";
export var mobSliderDiv = "SharedSlider-module--mobSliderDiv--4dbf1";
export var ourStory = "SharedSlider-module--ourStory--36ca6";
export var singleBenefitTextBox = "SharedSlider-module--singleBenefitTextBox--e565a";
export var singleCaseChallenge = "SharedSlider-module--singleCaseChallenge--446cb";
export var singleCaseContentBlock = "SharedSlider-module--singleCaseContentBlock--6464a";
export var singleCaseImageBox1 = "SharedSlider-module--singleCaseImageBox1--14ad1";
export var singleCaseImageBox2 = "SharedSlider-module--singleCaseImageBox2--9f02e";
export var singleCaseSolution = "SharedSlider-module--singleCaseSolution--36563";
export var singleCaseTestimonial = "SharedSlider-module--singleCaseTestimonial--9b5f7";
export var singleCaseTestimonialInner = "SharedSlider-module--singleCaseTestimonialInner--7b6ac";
export var singleCaseTextBox = "SharedSlider-module--singleCaseTextBox--9acdd";
export var sliderDate = "SharedSlider-module--sliderDate--cda99";
export var sliderItem = "SharedSlider-module--sliderItem--8b553";
export var testimonialBody = "SharedSlider-module--testimonialBody--e0869";
export var testimonialContent = "SharedSlider-module--testimonialContent--61eff";
export var testimonialName = "SharedSlider-module--testimonialName--a18c6";
export var yourBenefits = "SharedSlider-module--yourBenefits--1d4d4";
export var yourBenefitsIcon = "SharedSlider-module--yourBenefitsIcon--5c50f";
export var yourBenefitsInner = "SharedSlider-module--yourBenefitsInner--303ff";
export var yourBenefitsItem = "SharedSlider-module--yourBenefitsItem--96ae9";